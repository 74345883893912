import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CardListHome from "../components/cardListHome";
import "../components/layout.css";

/* Component */

const IndexPage = () => {

return <Layout>
          <Seo title="Home" />
          <div className="centered-content">
            <Link to={`/about-ofm`} style={{textDecoration: 'none'}}>
              <p className="call-to-action">ABOUT</p>
            </Link>
            <Link to={`/contact`}>
                  <p className="call-to-action">CONTACT</p>
            </Link>
          </div>
            <CardListHome />
      </Layout>
}

export default IndexPage;