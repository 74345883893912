import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CardListHome = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        navItems {
          bookingLinkEmailAddress
          contentful_id
          pageLinkTitle
          modelName
          homeNavigationProfileImage {
            gatsbyImage(
              width: 300
              formats: AUTO
              height: 420
              placeholder: TRACED_SVG
              quality: 95
            )
          }
        }
      }
    }
  `)
  return (
    <div className="cardlist">
      {data.contentfulHomepage.navItems.map(item => (
        <div className="image-container" key={item.contentful_id}>
          <Link to={item.pageLinkTitle}>
            <div className="portrait">
              <GatsbyImage
                alt="model"
                className="picture"
                image={getImage(item.homeNavigationProfileImage.gatsbyImage)}
              />
            </div>
          </Link>
          <div className="row-box">
            <Link to={item.pageLinkTitle}>
              <p className="call-to-action">{item.modelName}</p>
            </Link>
            <a className="call-to-action" href={item.bookingLinkEmailAddress}>
              BOOK
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CardListHome
